import { IItem } from '@rediredi/types'

export const useItemRedirect = (item?: IItem) => {
  if (!item) return

  const config = useRuntimeConfig()
  const globalStore = useGlobalStore()
  const { event, metaEvent } = useEvent()
  const currency = globalStore.storeCurrency

  const qs: Record<string, string> = {}

  if (globalStore.store.salesperson) {
    qs.salespersonId = globalStore.store.salesperson.id
  }

  window.open(
    `${config.public.API_URL}/inventory/storefront/${item.id}/redirect?${new URLSearchParams(qs)}`,
    '_blank',
  )

  event('generate_lead', {
    currency: currency || item.baseVariant.price?.currency,
    value: item.baseVariant.price?.amount,
  })

  metaEvent('Lead', MetaEventsPayloads.lead())
}
